// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $tertiary;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container-fluid {
		.container-holder {
			>.column {
				flex-flow: row wrap;
				align-items: flex-start;
			}
		}
	}

	// header-top
	.header-top {
		background: $white;

		.container-fluid {
			max-width: 1140px;
		}

		// site-switcher
		.site-switcher {
			z-index: 100;
			margin-left: auto;
			padding: 5px 0;

			ul {
				flex-direction: row;

				li {
					position: relative;
					display: flex;
					font-size: 12px;

					@include media-breakpoint-up(md) {
						font-size: 15px;
					}

					font-weight: 400;
					text-transform: uppercase;

					+li {
						margin-left: 10px;
						padding-left: 10px;
						border-left: 1px solid $black;
					}

					&.active {
						@include media-breakpoint-up(md) {
							font-size: 16px;
						}

						font-weight: 700;
					}

					&::before {
						content: "";
						z-index: 1;
						position: absolute;
						width: 5px;
						height: 5px;
						border-radius: 100%;
						opacity: 0;
						transition: opacity 0.5s;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}

					&::after {
						content: "";
						position: absolute;
						top: calc(100% + 4px);
						left: 0;
						right: 0;
						width: 37px;
						height: 53px;
						margin: 0 auto;
						background-size: 37px 53px;
						opacity: 0;
						transition: opacity 0.5s;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}

					&:hover,
					&.active {

						&::before,
						&::after {
							opacity: 1;
						}
					}

					a {
						display: flex;
						align-items: center;
						height: 30px;

						@include media-breakpoint-up(md) {
							height: 36px;
						}

						&::before {
							content: "";
							width: 19px;
							height: 26px;
							margin: -7px 7px 0 0;
							background-size: 19px 26px;

							@include media-breakpoint-down(md) {
								display: none;
							}
						}
					}

					&:nth-child(1) {

						&:hover,
						&.active {
							&::before {
								top: calc(100% + 48px);
								right: calc(50% - 5px);
								background: $red;
							}

							&::after {
								background-image: url('/images/map.svg');
							}
						}

						a {
							color: $brown;

							&::before {
								background-image: url('/images/shield-eyserhof.svg');
							}
						}
					}

					&:nth-child(2) {

						&:hover,
						&.active {
							&::before {
								top: calc(100% + 50px);
								right: calc(50% - -1px);
								background: $green;
							}

							&::after {
								background-image: url('/images/map.svg');
							}
						}

						a {
							color: $green-olive;

							&::before {
								background-image: url('/images/shield-hof-van-ulvend.svg');
							}
						}
					}
				}
			}
		}
	}

	// header-menu
	.header-menu {
		padding: 10px 0;

		// menu-toggle
		.menu-toggle {
			.navbar-toggler {
				display: flex;
				align-items: center;
				padding: 0;
				border: none;
				border-radius: 0;
				color: $black;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				transition: color 0.5s;

				i {
					width: 24px;
					font-size: 20px;
					line-height: 40px;
					transition: transform 0.5s;
				}

				&[aria-expanded="true"] {
					color: $primary;

					i {
						transform: rotate(180deg);

						&::before {
							content: "\f00d";
						}
					}
				}

				&:hover {
					color: $primary;
				}

				.navbar-toggler-label {
					margin-left: 5px;

					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}
		}

		// logo
		.logo {
			margin: 0 auto 0 15px;

			@include media-breakpoint-up(md) {
				margin-left: auto;
			}

			a {
				@extend .d-block;

				img {
					@extend .w-100;
					max-height: 110px;

					@include media-breakpoint-up(lg) {
						max-height: 160px;
					}

				}
			}

			&.logo-visual {
				margin-top: -46px;

				@include media-breakpoint-down(sm) {
					max-width: 206px;
				}
			}

			&.logo-text {
				display: none;

				a {
					img {
						max-height: 40px;

						@include media-breakpoint-down(lg) {
							max-height: 30px;
							margin-top: 5px;
						}
					}
				}
			}
		}

		// book-button
		div.book-button {
			a {
				border-radius: 100%;
				color: $primary;
				font-weight: 700;
				font-size: 16px;

				@include media-breakpoint-up(xl) {
					font-size: 18px;
				}

				line-height: 40px;
				text-align: center;
				text-decoration: none;
				transition: color 0.5s;

				&:hover {
					color: $secondary;
				}
			}
		}

		// site-switcher
		.site-switcher {
			display: none;
			margin-top: 8px;

			.navbar-nav {
				&.language {
					.nav-item {
						.nav-link {
							img {
								width: 20px;
								height: 20px;
							}
						}
					}
				}
			}
		}
	}

	&:not(.sticky) {
		div.book-button {
			@include media-breakpoint-down(md) {
				display: none;
			}

			position: absolute;
			right: 15px;
			// 50% = of parent height
			// 10px = section.header-top padding
			top: calc(50% + 10px);

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 90px;
				height: 90px;

				@include media-breakpoint-up(xl) {
					width: 110px;
					height: 110px;
				}

				padding: 10px;
				background: $primary;
				color: $white;
				line-height: 1.2;
				transition: 0.5s;

				&:hover {
					background: $secondary;
					color: $white;
				}
			}
		}
	}

	&.sticky {
		.header-menu {
			.logo {
				&.logo-visual {
					display: none;
				}

				&.logo-text {
					display: block;
				}
			}

			.site-switcher {
				display: none;
			}

			div.book-button {
				position: static;
			}
		}
	}
}

// Hide site-switcher icon on homepage
&.home .header .header-top .site-switcher ul li a::before {
	display: none;
}

// boeken badge
&.w3-channel-eyserhof {
	.header {
		div.book-button {
			a[href*="hofvanulvend"] {
				display: none;
			}
		}
	}
}

&.w3-channel-hof-van-ulvend {
	.header {
		div.book-button {
			a[href*="eyserhof"] {
				display: none;
			}
		}
	}
}
