&.accommodation-detail {
    .info {
        .properties {
            .icon {
                i {
                    &::before {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}