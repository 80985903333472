// channel
&.w3-channel-eyserhof {
	.hof-van-ulvend {
		display: none !important;
	}
}

&.w3-channel-hof-van-ulvend {
	.eyserhof {
		display: none !important;
	}
}

// headings
h1 {
	font-weight: 400;
	line-height: 1;
}

h2 {
	color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-style: italic;
}

// paragraph
p {
	min-height: 1rem;
}

// container
.container-fluid {
	max-width: 1660px;

	@include media-breakpoint-up(xl) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.container-narrow {
	max-width: 820px;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// gallery
.gallery {
	.gallery-item {
		.gallery-link {
			border-radius: 8px;
		}
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;
	overflow: unset;

	.card-image {
		border-radius: 8px;
	}

	.card-body {
		margin-top: 30px;
		padding: 0;

		.card-caption {
			.card-title-link {
				color: $primary;
			}

			.card-description {
				.list-icons {
					.list-item {
						.icon {
							i {
								&::before {
									font-weight: 300;
								}
							}
						}
					}
				}
			}
		}
	}
}

// link-arrow
a.link-arrow {
	display: inline-flex;
	align-items: center;
	color: $secondary;
	font-weight: 600;
	text-decoration: none;

	&::after {
		content: "\f105";
		margin-left: 7px;
		font-weight: 700;
		line-height: 1;
		font-family: $font-awesome;
		transition: 0.5s;
	}

	&:hover {
		color: $primary;
	}
}

// .w3-vue-booking-wrapper
.w3-vue-booking-wrapper .w3-daterangepicker .dp__main .dp__menu .dp__menu_inner {
	@include media-breakpoint-up(lg) {
		min-height: 328px;
	}
}
