// footer
.footer {

	// footer-ticker
	.footer-ticker {

		.container-one-column {
			padding-left: 15px;
			padding-right: 15px;
		}

		// news-ticker
		div.news-ticker {
			padding: 45px 0;
			background: $black;
			white-space: nowrap;
			overflow: hidden;

			ul {
				display: block !important;
				animation: ticker 30s linear infinite;

				li {
					display: inline-block;
					color: $white;
					font-style: italic;
					font-family: $font-family-secondary;

					&::before {
						content: '\f005\f005\f005';
						margin: 0 30px;
						font-family: $font-awesome;
						color: rgba($white, 0.5);
						font-size: 14px;
						font-weight: 400;
						font-style: normal;
					}

					a {
						color: $white;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		padding: 8vh 0;
		background-color: $cream;

		.footer-text {
			margin: 15px 0;

			h6 {
				margin-bottom: 15px;
				padding-bottom: 10px;
				border-bottom: 1px solid $black;
			}

			p {
				margin-bottom: 5px;
				line-height: 1.5;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					line-height: 2;

					a {
						color: $secondary;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	// footer-socket
	.footer-socket {
		padding-bottom: 30px;
		background-color: $cream;

		div.back-to-top {
			a {
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 auto;
				color: $black;
				font-size: 40px;
				line-height: 1;
				text-decoration: none;

				&::before {
					content: '\f0aa';
					font-family: $font-awesome;
					font-weight: 700;
				}

				&:hover {
					color: $primary;
				}
			}
		}
	}
}
