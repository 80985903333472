// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// eyserhof colors
$red: #c8746e; // primary
$brown: #9b867a; // secondary
$cream: #e7e7e0; // tertiary

// hof van ulvend colors
$green: #5c8579; // primary
$green-olive: #8d9385; // secondary
$green-cream: #eaeae6; // tertiary

$white: #fff;
$black: #231f20;

// css3 root vars setup
:root {
	@include defineColorHSLA(--w3-primary-color, $red);
	@include defineColorHSLA(--w3-secondary-color, $brown);
	@include defineColorHSLA(--w3-tertiary-color, $cream);
}

// theme-colors
$primary: var(--w3-primary-color);
$secondary: var(--w3-secondary-color);
$tertiary: var(--w3-tertiary-color);

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
$font-family-primary: 'Mukta', sans-serif;
$font-family-secondary: 'Merriweather', serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$line-height-base: 1.7;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $tertiary;
$dark: $secondary;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
