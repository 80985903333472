.header-menu-overlay {
	flex-direction: column;
	transition: 0.5s;
	overflow: hidden;

	&.show {
		display: flex !important;
	}

	.menu {
		display: block !important;

		.navbar-nav {
			align-items: flex-start;

			.nav-item {
				color: $black;
				font-weight: 700;
				line-height: 1;

				.nav-link {
					display: flex;
					align-items: center;
					color: $black;

					&:hover {
						padding-left: 5px;
						color: $primary;
					}
				}

				&.show,
				&.active {
					>.nav-link {
						padding-left: 5px;
						color: $primary;
					}
				}

				&.dropdown {
					.dropdown-toggle {
						&::after {
							border: none;
						}
					}

					.dropdown-menu {
						&.show {
							@include media-breakpoint-down(lg) {
								position: static !important;
								transform: none !important;
								margin: 0;
								padding: 0;
								border: none;
								box-shadow: none;
								background: transparent;
							}

							@include media-breakpoint-up(xl) {
								top: 0 !important;
								left: 300px !important;
								min-width: 300px;
								margin: 0;
								padding: 0;
								border: none;
								border-radius: 0;
								box-shadow: none;
								background: transparent;
								transform: none !important;
							}
						}

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							font-size: 18px;
							font-weight: 400;
							font-style: normal;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		// menu-large
		&.menu-large {
			padding-top: 40px;

			.navbar-nav {
				>.nav-item {
					font-size: 20px;
					font-style: italic;
					font-family: $font-family-secondary;

					@include media-breakpoint-up(xl) {
						font-size: 24px;
					}

					>.nav-link {
						&::after {
							content: '\f178';
							font-family: $font-awesome;
							margin-left: 10px;
							color: $primary;
							font-size: 24px;
							font-weight: 700;
							font-style: normal;
						}
					}
				}
			}
		}

		// menu-small
		&.menu-small {
			margin-top: 30px;

			.navbar-nav {
				.nav-item {
					font-size: 18px;
				}
			}
		}
	}

	// contact-info
	div.contact-info {
		margin-top: 45px;

		ul {
			align-items: center;
			justify-content: center;

			li {
				color: $secondary;

				+li {
					margin-left: 25px;
				}

				&:nth-last-child(-n+2) {
					font-size: 24px;

					span {
						display: none;
					}
				}

				a {
					color: $secondary;
					text-decoration: underline;

					&:hover {
						color: $primary;
					}
				}
			}
		}
	}

	// news-ticker
	div.news-ticker {
		margin-top: 15px;
		padding: 35px 0;
		background: $primary;
		white-space: nowrap;
		overflow: hidden;

		ul {
			display: block !important;
			animation: ticker 30s linear infinite;

			li {
				display: inline-block;
				color: $white;
				font-style: italic;
				font-family: $font-family-secondary;

				&::before {
					content: '\f005\f005\f005';
					margin: 0 30px;
					font-family: $font-awesome;
					color: rgba($white, 0.5);
					font-size: 14px;
					font-weight: 300;
					font-style: normal;
				}

				a {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	@keyframes ticker {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-100%);
		}
	}

}

// navbar-active
& {
	&::before {
		content: "";
		transition: 0.5s;
	}
}

&.navbar-active {
	&::before {
		content: "";
		z-index: 997;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.6);
	}
}
