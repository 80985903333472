// Eyserhof
&.w3-channel-eyserhof {
    .w3-vue-booking-wrapper .label-field,
    .w3-vue-booking-wrapper .w3-autocomplete .input-holder .label,
    .w3-vue-booking-wrapper .w3-checkbox .input-holder .label,
    .w3-vue-booking-wrapper .w3-daterangepicker .label,
    .w3-vue-booking-wrapper .w3-radio .input-holder .label,
    .w3-vue-booking-wrapper .w3-travelgroup .input-holder .label {
        color: $red;
    }
}

// Hof van ulvand
&.w3-channel-hof-van-ulvend {
    .w3-vue-booking-wrapper .label-field,
    .w3-vue-booking-wrapper .w3-autocomplete .input-holder .label,
    .w3-vue-booking-wrapper .w3-checkbox .input-holder .label,
    .w3-vue-booking-wrapper .w3-daterangepicker .label,
    .w3-vue-booking-wrapper .w3-radio .input-holder .label,
    .w3-vue-booking-wrapper .w3-travelgroup .input-holder .label {
        color: $green;
    }
}