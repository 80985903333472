// btn
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 18px;
	border-radius: 3px;
	font-weight: 400;
	line-height: 32px;

	// btn-primary
	&.btn-primary {
		background-color: $black;
		border-color: $black;
		color: $white;

		&:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .link-arrow;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
