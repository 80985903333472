// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 40px 0;
	background-color: $tertiary;

	.container-one-column {
		.container-holder {
			max-width: 880px;
		}
	}

	h3.subtitle {
		color: $primary;
		font-style: normal;
		font-weight: 400;
		font-family: $font-family-primary;
	}

	p:first-of-type {
		font-size: 18px;

		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
	}

	// quick-menu
	ul.quick-menu {

		li {
			margin: 10px 10px 0 0;

			a {
				@extend .btn;
				background-color: rgba($white, 0.5);

				img,
				i {
					margin-right: 10px;
					transition: 0.5s;
				}

				i {
					color: $primary;
					font-size: 20px;
					font-weight: 700;
				}

				&:hover {
					background-color: $secondary;
					color: $white;

					img {
						filter: brightness(0) invert(1);
					}

					i {
						color: $white;
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// eyecatcher-section
// =========================================================================
.eyecatcher-section {
	position: relative;
	height: 75vh;
	min-height: 400px;
	max-height: 720px;

	// owl-carousel
	.owl-carousel {
		min-height: unset !important; // overide

		.item {
			height: 75vh !important; // overide
			min-height: 400px;
			max-height: 720px;
			padding: unset; // overide

			.owl-container {
				position: relative;
				max-width: 750px;

				.owl-caption {
					text-shadow: none;

					.owl-title {
						@include font-size($h1-font-size);
						margin-bottom: 0;
						color: $white;
						font-weight: 400;
					}

					.owl-description {
						@include media-breakpoint-up(xl) {
							font-size: 20px;
						}
					}
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

}

// usps-section
// =========================================================================
.usps-section {
	padding: 30px 0;
	background-color: $tertiary;

	ul {
		@extend .custom-list-extra;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		li {
			margin: 2.5px 10px;
			padding-left: 30px !important;
			color: $secondary;
			font-size: 16px;
			font-style: italic;
			font-family: $font-family-secondary;

			@include media-breakpoint-up(xl) {
				margin: 0;
				flex-grow: 1;
			}

			&::before {
				font-size: 20px;
				line-height: 24px;
			}
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	padding: 6vh 0;
	background-color: $tertiary;

	p:first-of-type {
		font-size: 18px;

		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
	}

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}

	// link
	div.link {
		margin-top: 6vh;
		text-align: center;

		a.link {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}

// masonry-section
// =========================================================================
.masonry-collection-section {
	padding: 6vh 0;
	background-color: $tertiary;

	// collection
	.collection {
		&.masonry {
			.card-columns {
				@include media-breakpoint-up(lg) {
					column-count: 2;
				}

				@include media-breakpoint-up(xl) {
					column-count: 2;
				}

				.item {
					&:first-child {
						.card {
							background: transparent;
							border: none;
							word-wrap: unset;

							.card-image {
								display: none;
							}

							.card-img-overlay {
								position: static;
								background: transparent;
								padding: 0;

								.card-caption {
									color: inherit;
									text-shadow: none;

									.card-title {
										@include font-size($h2-font-size);
									}

									.card-description {
										color: $black;

										@include media-breakpoint-up(xl) {
											font-size: 20px;
										}
									}
								}
							}
						}
					}

					&:not(:first-child) {
						.card {
							border: none;
							border-radius: 8px;

							.card-img-overlay {
								display: flex;
								flex-direction: column;
								padding: 0;

								.card-caption {
									padding: 15px;
									background: linear-gradient(180deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
									text-shadow: none;

									@include media-breakpoint-up(xl) {
										padding: 30px;
									}

									.card-title {
										@include media-breakpoint-up(xl) {
											font-size: 30px;
										}

										&:hover {
											color: inherit;
										}
									}

									.card-subtitle,
									.card-description {
										opacity: 0;
										transition: opacity 0.5s;
									}
								}

								.card-buttons {
									opacity: 0;
									transition: opacity 0.5s;
									padding: 15px;

									@include media-breakpoint-up(xl) {
										padding: 30px;
									}

									.card-btn {
										@extend .btn;
										@extend .btn-primary;
									}
								}
							}

							&:hover {
								.card-img-overlay {
									.card-caption {

										.card-subtitle,
										.card-description {
											opacity: 1;
										}
									}

									.card-buttons {
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro
	.intro {
		p:first-of-type {
			font-size: 18px;

			@include media-breakpoint-up(xl) {
				font-size: 20px;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 400px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 400px;
			max-height: 720px;
			padding: unset; // overide
			align-items: flex-start;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 40%;
				background: linear-gradient(180deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
			}

			.owl-container {
				position: relative;
				padding-top: 6vh;
				padding-bottom: 6vh;

				.owl-caption {
					text-align: left;
					text-shadow: none;

					.owl-title {
						@include font-size($h1-font-size);
						margin-bottom: 0;
						color: $white;
						font-weight: 400;
					}

					.owl-description {
						@include media-breakpoint-up(xl) {
							font-size: 20px;
						}
					}
				}
			}
		}

		.owl-nav {

			.owl-prev,
			.owl-next {
				text-shadow: none;
				font-size: 20px;
			}
		}

		.owl-dots {
			display: none;
		}
	}

}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// search-book-section
// =========================================================================
.search-book-section {

	.w3-vue-booking-wrapper {
		&.w3-searchbook-widget {

			.w3-filter-collection {
				background: $tertiary;
				padding-bottom: 30px;

				.filters {
					max-width: 1140px;
					width: 100%;
					margin: 0 auto;
					padding: 0 15px 15px 15px;

					@include media-breakpoint-down(md) {
						.filter-element {
							padding-left: 0;
						}
					}

					@include media-breakpoint-up(xl) {
						.filter-element-0 {
							padding-left: 0;
						}
					}
				}

				.tags {
					max-width: 1140px;
					width: 100%;
					margin: 0 auto;
					padding: 0 15px;
				}
			}

			.results {
				max-width: 1630px;
				width: 100%;
				margin: 0 auto;
				padding: 0 15px;

				.sorting {
					position: relative;
					max-width: 1110px;
					width: 100%;
					margin: 0 auto 30px auto;
					padding-top: 15px;

					&::before {
						content: "";
						z-index: 1;
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 0;
						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						border-top: 22px solid $cream;
					}
				}
			}
		}
	}
}
