.overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 100px 0;
    display: flex;
    align-items: center;
    background-image: linear-gradient(rgba($black, .5), rgba($black, 0));

    &.container-one-column {

        .container-holder {

            // make this element as container
            @include make-container();

            // Responsive containers that are 100% wide until a breakpoint
            @each $breakpoint, $container-max-width in $container-max-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    max-width: $container-max-width;
                }
            }


            .column.default {
                max-width: 880px;
            }
        }
    }

    .page-title {
        h1 {
            color: $white;
        }
    }

    h3.subtitle {
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-family: $font-family-primary;
    }

    p:first-of-type {
        font-size: 18px;

        @include media-breakpoint-up(xl) {
            font-size: 20px;
        }
    }

    // quick-menu
    ul.quick-menu {
		@include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        li {
            margin: 10px 10px 0 0;

            a {
                @extend .btn;
                background-color: $white;

                img,
                i {
                    margin-right: 10px;
                    transition: 0.5s;
                }

                i {
                    color: $primary;
                    font-size: 20px;
                    font-weight: 700;
                }

                &:hover {
                    background-color: $secondary;
                    color: $white;

                    img {
                        filter: brightness(0) invert(1);
                    }

                    i {
                        color: $white;
                    }
                }
            }
        }
    }
}
